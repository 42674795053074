<template>
  <div class="col-lg-4 col-md-6 px-0-8 py-0-8">
    <b-link
      class="item border d-block px-3 py-2-5 position-relative"
      :class="{ 'card--activated': activated && !moderated }"
      @click="routeTo()"
    >
      <div class="d-flex pb-1-5 align-items-center">
        <i :class="icon" class="mr-1-5"></i>
        <h6 class="mb-0">{{ title }}</h6>
      </div>
      <p>{{ description }}</p>
      <span class="salon-arrow"></span>
    </b-link>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SalonSettinsCard',
  props: {
    activated: Boolean,
    moderated: Boolean,
    title: {
      type: String,
      required: true
    },
    description: {
      type: String
    },
    icon: {
      type: String
    },
    to: {
      type: String
    }
  },
  data() {
    return {
      errorMessage: {
        type: 'info',
        messageI18Key: 'general.alerts.empty_block'
      }
    }
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo'
    })
  },
  methods: {
    ...mapActions('userMessages', {
      setError: 'setError'
    }),
    routeTo() {
      if (!this.activated) {
        this.setError(this.errorMessage)
        return
      } else if (this.activated && !this.accountInfo.email_verified_at) {
        this.$emit('toggle', true)
        return
      }
      this.$router.push(this.to)
    }
  }
}
</script>

<style lang="css">
.salon-options-list .item.card--activated {
  box-shadow: 0 0 0 1px rgba(255, 129, 46, 0.5);
}
</style>
