<template>
  <main class="verification">
    <b-modal
      id="verification-modal"
      header-class="modal-header pt-md-1 px-1-5 pb-0 border-bottom-0"
      body-class="px-3 pb-0 pt-0"
      footer-class="px-3 pb-3 pt-0"
      centered
    >
      <template v-slot:modal-header="{ close }">
        <div class="w-100 d-flex justify-content-end">
          <div>
            <font-awesome-icon
              :icon="['far', 'times']"
              class="icon-close-mobile-grey ml-1-5"
              @click="close()"
            ></font-awesome-icon>
          </div>
        </div>
      </template>
      <div class="my-2 w-100 d-flex justify-content-center">
        <img src="@/assets/images/verification/email-verification.svg" />
      </div>
      <div class="mt-2 mb-1 text-center">
        <h3 class="mb-2">{{ $t('email-verify.acc-verification') }}</h3>
        <p class="d-flex">{{ $t('email-verify.acc-message') }} {{ accountInfo ? `  (${accountInfo.email})` : '' }}</p>
      </div>
      <template v-slot:modal-footer="{ close }">
        <div class="w-100 d-flex">
          <div class="col-6 pl-0">
            <button class="btn btn-block btn-outline-secondary-custom py-1" @click="close()">
              <span>{{ $t('email-verify.cancel') }}</span>
            </button>
          </div>
          <div class="col-6 pr-0">
            <button class="btn btn-primary btn-block py-1" @click="resendMessage">
              <span>{{ $t('email-verify.resend') }}</span>
            </button>
          </div>
        </div>
      </template>
    </b-modal>
  </main>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      accountInfo: 'user/getAccountInfo'
    })
  },
  methods: {
    ...mapActions({
      emailResend: 'user/emailResend'
    }),
    resendMessage() {
      this.$bvModal.hide('verification-modal')
      this.emailResend()
    }
  }
}
</script>
