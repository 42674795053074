<template>
  <main class="navbar-offset-tablet">
    <PageNavigator
      id="breadcrumbs-block"
      :page-title="$t('general.breadcrumbs-index-label')"
      class="d-flex flex-wrap flex-md-nowrap justify-content-between align-items-center border-bottom px-1-5 py-0-8 position-fixed bg-white w-100"
    >
      <template v-slot:action>
        <div class="d-flex">
          <div>
            <span
              v-if="!moderationCompleted && !loadingGeneralInfo"
              class="text-primary d-none d-md-block font-weight-medium poppins-font text-left px-1 px-md-1-5 mr-auto"
            >
              {{ $t('general.moderation.verification_info_message') }}
            </span>
          </div>
          <div class="d-flex flex-wrap mt-1 mt-md-0">
            <div class="d-flex" style="height: 3rem">
              <router-link
                to="/salon-partners"
                class="btn btn-primary h-auto text-nowrap px-1 px-md-2 py-1 mr-1 mr-md-1-5  fz-base"
              >
                <span class="font-weight-medium poppins-font">
                  Coiffeurbedarf
                </span>
              </router-link>
              <b-link
                to="/contact-us"
                class="btn btn-primary h-auto text-nowrap px-1 px-md-2 py-1 mr-1 mr-md-1-5 ml-auto fz-base"
              >
                <span class="font-weight-medium poppins-font">
                  Support
                </span>
              </b-link>
              <button
                v-if="moderationDisabled"
                :disabled="Boolean(getModerateFlag)"
                class="btn btn-primary h-auto px-1 px-md-2 py-1 fz-base text-nowrap"
                @click.prevent="sendToModerationRequest()"
              >
                <span class="font-weight-medium poppins-font">
                  {{ $t('general.breadcrumbs-disabled-label') }}
                </span>
              </button>
              <button v-if="moderationInProgress" class="btn btn-warning px-1 px-md-2 py-1 disabled-pe text-nowrap">
                <span class="font-weight-medium poppins-font text-white">
                  <font-awesome-icon :icon="['far', 'clock']" class="fa-w-14 text-white mr-0-5"></font-awesome-icon>
                  {{ $t('general.breadcrumbs-moderate-label') }}
                </span>
              </button>
              <button v-if="moderationCompleted" class="btn btn-success px-1 px-md-2 py-1 disabled-pe text-nowrap">
                <span class="font-weight-medium poppins-font">
                  <font-awesome-icon :icon="['far', 'check']" class="fa-w-14 text-white mr-0-5"></font-awesome-icon>
                  {{ $t('general.breadcrumbs-active-label') }}
                </span>
              </button>
            </div>
            <div class="w-100 d-block d-md-none pt-1">
              <span
                v-if="!moderationCompleted && !loadingGeneralInfo"
                class="text-primary font-weight-medium poppins-font text-left"
              >
                {{ $t('general.moderation.verification_info_message') }}
              </span>
            </div>
          </div>
        </div>
      </template>
    </PageNavigator>
    <div
      v-if="!loadingGeneralInfo"
      id="general-content"
      class="px-1-5 pt-7-5 full-page-height salon-flow"
      :style="{ paddingTop: `${contentPadding}px!important` }"
    >
      <div class="d-flex flex-wrap salon-options-list my-0">
        <SalonSettinsCard
          v-for="(page, key) in pageListByRole"
          :key="`page-${key}`"
          :activated="page.activated"
          :moderated="page.moderated"
          :title="$t(page.i18KeyTitle)"
          :description="$t(page.i18KeyDescription)"
          :icon="page.icon"
          :to="page.to"
          @toggle="isVerified($event)"
        >
        </SalonSettinsCard>
      </div>
    </div>
    <VerificationPopup />
  </main>
</template>

<script>
import PageNavigator from '@/components/PageNavigator'
import SalonSettinsCard from '@/components/salon/SalonSettinsCard'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import config from 'config'
import VerificationPopup from '@/views/verification/VerificationPopup'

export default {
  name: 'SalonGeneral',
  components: {
    PageNavigator,
    SalonSettinsCard,
    VerificationPopup
  },
  data() {
    return {
      loadingGeneralInfo: false,
      pagesList: [
        {
          activated: true,
          moderated: false,
          i18KeyTitle: 'salon-info.block-title',
          i18KeyDescription: 'salon-info.block-description',
          icon: 'salon-information',
          to: '/salon/info',
          key: 'main_info',
          access: ['owner', 'admin']
        },
        {
          activated: false,
          moderated: false,
          i18KeyTitle: 'salon-working-hours.block-title',
          i18KeyDescription: 'salon-working-hours.block-description',
          icon: 'salon-working-hours',
          to: '/salon/working-hours',
          key: 'workingHours',
          access: ['owner', 'salon_manager', 'admin']
        },
        {
          activated: false,
          moderated: false,
          i18KeyTitle: 'salon-services.block-title',
          i18KeyDescription: 'salon-services.block-description',
          icon: 'salon-services',
          to: '/salon/services',
          key: 'services',
          access: ['owner', 'salon_manager', 'admin']
        },
        {
          activated: false,
          moderated: false,
          i18KeyTitle: 'salon-employees.block-title',
          i18KeyDescription: 'salon-employees.block-description',
          icon: 'salon-staff',
          to: '/salon/staff',
          key: 'salon_staff',
          access: ['owner', 'salon_manager', 'admin']
        },
        {
          activated: false,
          moderated: false,
          i18KeyTitle: 'salon-gallery.block-title',
          i18KeyDescription: 'salon-gallery.block-description',
          icon: 'salon-gallery',
          to: '/salon/gallery',
          key: 'images',
          access: ['owner', 'salon_manager', 'admin']
        },
        {
          activated: false,
          moderated: false,
          i18KeyTitle: 'salon-days-off.block-title',
          i18KeyDescription: 'salon-days-off.block-description',
          icon: 'salon-weekends',
          to: '/salon/days-off',
          key: 'daysOff',
          access: ['owner', 'salon_manager', 'admin']
        }
      ],
      windowWidth: window.innerWidth,
      contentPadding: 75
    }
  },
  computed: {
    ...mapGetters({
      generalInfo: 'salons/getGeneral',
      accountInfo: 'user/getAccountInfo',
      userRole: 'user/getRole',
      hideDeviceInfoMessage: 'user/hideDeviceInfoMessage',
      getModerateFlag: 'salons/getModerateFlag'
    }),
    clientAppUrl() {
      return config.clientAppUrl
    },
    clientAppProductUrl() {
      return `${this.clientAppUrl}/salon-partners`
    },
    salonId() {
      return this.accountInfo.salon_id
    },
    moderationDisabled() {
      return this.generalInfo.status === 'Disabled' || this.generalInfo.status === undefined
    },
    moderationInProgress() {
      return this.generalInfo.status === 'Moderate'
    },
    moderationCompleted() {
      return this.generalInfo.status === 'Active'
    },
    pageListByRole() {
      return this.pagesList.filter(item => item.access.includes(this.userRole))
    }
  },
  watch: {
    siteLang: {
      handler(newVal) {
        this.resizeHandler()
      }
    }
  },
  mounted() {
    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)

    if (window.matchMedia('(max-width: 767px)').matches) {
      if (!this.hideDeviceInfoMessage) {
        this.broserSupportInfo()
      }
    }
    if (this.salonId !== undefined && this.salonId !== null) {
      this.loadingGeneralInfo = true
      this.fetchGeneralInfo(this.accountInfo.salon_id)
        .then(response => {
          this.activatedPageItems(response)

          this.loadingGeneralInfo = false
        })
        .catch(() => {
          this.loadingGeneralInfo = false
        })
    }
    if (this.salonId) {
      this.fetchCheckModeration({ salon_id: this.salonId })
        .then(result => {
          if (result) {
            this.moderateIsDeclined()
          }
        })
        .catch(err => this.moderateIsDeclined())
    } else this.moderateIsDeclined()
    if (this.accountInfo && !this.accountInfo.email_verified_at) {
      this.$bvModal.show('verification-modal')
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeHandler)
  },
  methods: {
    ...mapActions({
      fetchInfo: 'salons/getInfo',
      fetchGeneralInfo: 'salons/getGeneral',
      fetchCheckModeration: 'salons/checkModeration',
      sendToModeration: 'salons/sendToModeration',
      setError: 'userMessages/setError',
      setSuccess: 'userMessages/setSuccess'
    }),
    ...mapMutations({
      setShowDeviceInfoMessage: 'user/SET_SHOW_DEVICE_INFO_MESSAGE',
      moderateIsDeclined: 'salons/MODERATE_IS_DECLINED'
    }),
    resizeHandler() {
      this.windowWidth = window.innerWidth

      if (window.innerWidth <= 767) {
        let breadcrumbsBlock = document.getElementById('breadcrumbs-block')
        if (breadcrumbsBlock.offsetHeight) {
          this.contentPadding = breadcrumbsBlock.offsetHeight
        }
      } else {
        this.contentPadding = 75
      }
    },
    isVerified(val) {
      if (val) this.$bvModal.show('verification-modal')
    },
    broserSupportInfo() {
      const h = this.$createElement
      const messageVNode = h('div', { class: ['foobar'] }, [
        h('p', { class: ['h6 text-center mb-0 text-black pt-2-5 pb-1-5'] }, [this.$t('general.moderation.mobile_info')])
      ])

      this.$bvModal
        .msgBoxOk([messageVNode], {
          centered: true,
          size: 'md',
          footerClass: 'pt-0 pb-4 px-4 has-cusstom-btns',
          bodyClass: ' pb-2 px-2'
        })
        .then(value => {
          this.resizeHandler()
          this.setShowDeviceInfoMessage(true)
        })
        .catch(err => {})
    },
    sendToModerationRequest() {
      this.fetchInfo(this.accountInfo.salon_id).then(response => {
        if (response && response.billingDetails) {
          if (this.salonId !== undefined) {
            this.sendToModeration({ salon_id: this.salonId })
              .then(data => {
                this.setSuccess({
                  type: 'success',
                  messageI18Key: 'general.alerts.request_moderation_success'
                })
              })
              .catch(error => {
                if (error.data !== null) {
                  let blockNameI18Keys = []
                  let blockNameI18KeysString = ''

                  error.data.forEach(block => {
                    blockNameI18Keys.push(this.$t(`general.moderation.${block}`))
                  })

                  blockNameI18KeysString = blockNameI18Keys.join(', ')

                  this.setError({
                    type: 'info',
                    message: `${this.$t(`general.moderation.main_message`)} ${blockNameI18KeysString}`
                  })
                }
              })
          }
        } else if (response && !response.billingDetails) this.$router.push('/salon/card-create')
      })
    },
    activatedPageItems(statuses) {
      for (let [key, value] of Object.entries(statuses)) {
        this.pagesList.find(page => {
          if (this.generalInfo.status === 'Active') page.moderated = true
          if (page.key === key) page.activated = value
        })
      }
    }
  }
}
</script>
